<template>
  <AppTable v-if="kills" :fields="fields" :items="items">
    <template #cell()="{ item: left, field: { key: top } }">
      <span class="kills">{{ kills[left][top] || 0 }}</span>
      <span class="deaths">{{ kills[top][left] || 0 }}</span>
      <span class="dominance" :class="getSignClass((kills[left][top] || 0) - (kills[top][left] || 0))">{{
        ((kills[left][top] || 0) - (kills[top][left] || 0)) | signed
      }}</span>
    </template>
    <template #cell(player)="{ index }">
      <ImgCell>
        <div>
          <div>{{ (leftTeam && leftTeam.players[index].name) || leftTeam.players[index].agent_name }}</div>
          <div>{{ leftTeam && leftTeam.name }}</div>
        </div>
      </ImgCell>
    </template>
  </AppTable>
</template>

<script>
import px from 'vue-types'

import ImgCell from '@/components/generic/ImgCell.vue'
import AppTable from '@/components/generic/Table.vue'

export default {
  name: 'MatchStatKillMatrix',
  components: { ImgCell, AppTable },
  props: {
    kills: px.object,
    teams: px.arrayOf(px.object),
  },
  computed: {
    fields() {
      return [
        { key: 'player', label: '' },
        ...(this.topTeam?.players.map((player, index) => ({ key: player.puuid, label: player.name, index })) || []),
      ]
    },
    items() {
      return this.leftTeam?.players.map(player => player.puuid)
    },
    leftTeam() {
      return this.teams[0]
    },
    topTeam() {
      return this.teams[1]
    },
  },
  methods: {
    getSignClass(num) {
      return num > 0 ? 'plus' : num < 0 ? 'minus' : 'zero'
    },
  },
  filters: {
    signed: val => (val < 0 ? val : `+${val}`),
  },
}
</script>

<style lang="scss" scoped>
.kills,
.deaths,
.dominance {
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
}

.kills {
  background: $gray-700;
}

.deaths {
  background: $gray-800;
}

.dominance {
  &.plus {
    color: $atk;
  }

  &.minus {
    color: $def;
  }
}
</style>
