<template>
  <ApiLoadingController :fetch="fetch" :params="params">
    <template #default="{ data }">
      <ErrorAlert v-if="inconsistentData"> Inconsistent data detected. </ErrorAlert>
      <ScrimPerformance v-bind="data" />
    </template>
  </ApiLoadingController>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import { getScrimAdvancedRoundInfo } from '@/api/scrims'
import ApiLoadingController from '@/components/controllers/ApiLoadingController.vue'
import ErrorAlert from '@/components/generic/ErrorAlert.vue'
import ScrimPerformance from '@/components/scrim/ScrimPerformance.vue'
import calcScrimPlayerStatsFromKillfeed from '@/utils/calcScrimPlayerStatsFromKillfeed.js'
import reportException from '@/utils/reportException'

export default {
  name: 'ScrimPerformancePage',
  components: {
    ScrimPerformance,
    ApiLoadingController,
    ErrorAlert,
  },
  props: {
    scrimId: px.string.isRequired,
  },
  data: () => ({
    inconsistentData: false,
  }),
  inject: ['scrimData'],
  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
    }),
    params() {
      return this.scrimId
    },
    scrim() {
      return this.scrimData.scrim
    },
  },
  methods: {
    async fetch(scrimId, options) {
      let failedApiCalls = 0

      const scrimAdvancedData = await Promise.all(
        this.scrimData.scrim.roundData.map(async round => {
          try {
            const data = await getScrimAdvancedRoundInfo(scrimId, round.round_num, options)
            return data
          } catch (e) {
            failedApiCalls++
            if (!/^Network error 40[3-4]/.test(e.message)) {
              reportException(e)
            }
          }
        })
      )

      const playerStats = {}
      if (scrimAdvancedData?.length > 0) {
        scrimAdvancedData.forEach(roundData => {
          calcScrimPlayerStatsFromKillfeed(roundData.killfeed_detections_expand?.events, playerStats)
        })
      }
      return {
        teams: this.scrim?.teams?.map(team => ({
          ...team,
          players: team.players?.map(player => ({
            ...player,
            name: player.name,
            ...(playerStats[player.puuid] ?? {}),
            agent_name: this.agentsById[player.agent]?.name,
          })),
        })),
        kills: scrimAdvancedData?.length > 0 ? this.extractKills(scrimAdvancedData) : {},
        incompleteMlData: failedApiCalls > 0,
      }
    },
    extractKills(advancedData) {
      const kills = {}
      advancedData.forEach(round =>
        round.killfeed_detections_expand?.events?.forEach(kill => {
          kills[kill.source_puuid] = kills[kill.source_puuid] || {}
          kills[kill.source_puuid][kill.target_puuid] = (kills[kill.source_puuid][kill.target_puuid] || 0) + 1
        })
      )
      return kills
    },
  },
}
</script>

<style lang="scss" scoped></style>
