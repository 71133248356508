var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.teams
        ? _c("MatchStatKillBars", {
            staticClass: "mb-6 mt-4",
            attrs: { teams: _vm.teams }
          })
        : _vm._e(),
      _c("ScrimStatKillMatrix", {
        attrs: { kills: _vm.kills, teams: _vm.teams }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }