<template>
  <div>
    <MatchStatKillBars v-if="teams" :teams="teams" class="mb-6 mt-4" />

    <ScrimStatKillMatrix :kills="kills" :teams="teams" />
  </div>
</template>

<script>
import px from 'vue-types'

import MatchStatKillBars from '@/components/match/stats/KillBars.vue'
import ScrimStatKillMatrix from '@/components/scrim/ScrimKillMatrix.vue'

export default {
  components: {
    MatchStatKillBars,
    ScrimStatKillMatrix,
  },
  props: {
    match_id: px.string,
    teams: px.arrayOf(px.object),
    kills: px.object,
  },
}
</script>

<style lang="scss" scoped></style>
